<template>
  <v-theme-provider :dark="dark">
    <div>
      <base-img
        :src="require('@/assets/logo.svg')"
        contain
        max-width="118"
        width="100%"
      />

      <base-title
        size="text-body-1"
        space="4"
        title="ScrumOnDemand"
        weight="regular"
      />

      <base-body>
        Join us as we unleash the power of ScrumOnDemand to help you and the wider community grow your potential and build skills for success.
      </base-body>

      <!-- <base-btn
        class="mb-12"
        color="white"
        outlined
      >
        More Info
      </base-btn> -->
    </div>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'BaseInfo',

    props: {
      title: String,
      dark: Boolean,
    },

    data: () => ({
      business: [
        {
          icon: 'mdi-map-marker-outline',
          title: 'Address',
          text: 'Elyon Innovations LLC <br> 1111 Main Street Suite 405 <br> Vancouver, WA 98660',
        },
        {
          icon: 'mdi-cellphone',
          title: 'Phone',
          text: '<a href="tel:360-696-5892">360-696-5892</a>, <a href="tel:+01-360-696-5892">+01-360-696-5892</a> for International calls',
        },
        {
          icon: 'mdi-email',
          title: 'Email',
          text: '<a href="mailto:info@scrumteamondemand.com?subject=ScrumOnDemand%20Question">info@scrumteamondemand.com</a>',
        },
      ],
    }),
  }
</script>
