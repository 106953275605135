import Vue from 'vue'
import Vuex from 'vuex'

import news from './modules/news-module'
import community from './modules/community-module'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState({
    storage: window.sessionStorage,
  })],
  state: {
    Sidebar_drawer: null,
    Customizer_drawer: false,
    SidebarColor: '#1d2228', // Change Sidebar Color || 'white', | "#2b2b2b" | "rgb(44, 59, 164)" | "rgb(96, 44, 164)" | "rgb(151, 210, 219)" | "rgb(77, 86, 100)"
    SidebarBg: '',
    navbarColor: 'white',
    setHorizontalLayout: false, // Horizontal layout
  },
  mutations: {
    SET_SIDEBAR_DRAWER (state, payload) {
      state.Sidebar_drawer = payload
    },
    SET_CUSTOMIZER_DRAWER (state, payload) {
      state.Customizer_drawer = payload
    },
    SET_SIDEBAR_COLOR (state, payload) {
      state.SidebarColor = payload
    },
    SET_NAVBAR_COLOR (state, payload) {
      state.navbarColor = payload
    },
    SET_LAYOUT (state, payload) {
      state.setHorizontalLayout = payload
    },
  },
  actions: {},
  getters: {},
  modules: {
    news: {
      namespaced: true,
      state: news.state,
      mutations: news.mutations,
      getters: news.getters,
      actions: news.actions,
    },
    community: {
      namespaced: true,
      state: community.state,
      mutations: community.mutations,
      getters: community.getters,
      actions: community.actions,
    },
  },
})
