import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import './plugins'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import store from './store/store'

Vue.use(VueAxios, axios)
Vue.config.productionTip = false
Vue.use(VueReCaptcha, {
  siteKey: '6Lc1lEQaAAAAAJkc0Ay1X0qlS6vRStexLnp_9bje',
})
new Vue({
  router,
  vuetify,
  store,
  render: h => h(App),
}).$mount('#app')
