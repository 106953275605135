import service from '../../services/factory-service'
import Vue from 'vue'

const newsService = service.get('news')

const state = {
  news: {},
  status: '',
  list: [],
  paginated: {},
  categories: [],
  tags: [],
}

const getters = {
  news: (newsState) => newsState.news,
  list: (newsState) => newsState.list,
  paginated: (newsState) => newsState.paginated,
  categories: (newsState) => newsState.categories,
  tags: (newsState) => newsState.tags,
}

const actions = {
  getCategories: ({ commit }) => {
    commit('newsRequest')
    newsService.getCategories()
      .then((result) => {
        commit('newsCategory', result.data)
      },
        (errors) => {
          commit('newsError', errors)
        })
  },
  getTags: ({ commit }) => {
    commit('newsRequest')
    newsService.getTags()
      .then((result) => {
        commit('newsTag', result.data)
      },
        (errors) => {
          commit('newsError', errors)
        })
  },
  getNews: ({ commit }) => {
    commit('newsRequest')
    newsService.getNews()
      .then((result) => {
        commit('newsList', result.data)
      },
        (errors) => {
          commit('newsError', errors)
        })
  },
}

const mutations = {
  newsRequest: (newsState) => {
    newsState.status = 'attempting request for news data'
  },
  newsSuccess: (newsState, newsResp) => {
    newsState.status = 'success'
    Vue.set(newsState, 'news', newsResp)
  },
  newsList: (newsState, list) => {
    newsState.status = 'success'
    Vue.set(newsState, 'list', list)
  },
  newsError: (newsState) => {
    newsState.status = 'error'
  },
  newsCategory: (newsState, list) => {
    newsState.status = 'success'
    Vue.set(newsState, 'categories', list)
  },
  newsTag: (newsState, list) => {
    newsState.status = 'success'
    Vue.set(newsState, 'tags', list)
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
