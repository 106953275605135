// Imports
import Vue from 'vue'
import Router from 'vue-router'
// import RedirExternal from '../layouts/home/RedirExternal.vue'

Vue.use(Router)

const router = new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash }
    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      component: () => import('@/layouts/home/Index.vue'),
      children: [
        {
          path: '',
          name: 'Home',
          component: () => import('@/views/home/Index.vue'),
        },
        {
          path: 'about',
          name: 'About Us',
          component: () => import('@/views/about/Index.vue'),
          meta: { src: require('@/assets/about.jpg') },
        },
        {
          path: 'news',
          name: 'News',
          component: () => import('@/views/news/Index.vue'),
          meta: { src: require('@/assets/news.jpg') },
        },
        {
          path: 'news/:slug',
          name: 'Article',
          component: () => import('@/views/news/Article.vue'),
          meta: { src: require('@/assets/article.jpg') },
        },
        {
          path: 'communities',
          name: 'Communities',
          component: () => import('@/views/marketing/Index.vue'),
          meta: { src: require('@/assets/communities.png') },
        },
        {
          path: 'contact-us',
          name: 'Contact',
          component: () => import('@/views/contact-us/Index.vue'),
          meta: { src: require('@/assets/contact.jpg') },
        },
        // {
        //   path: 'kitchen-sink',
        //   name: 'Sink',
        //   component: () => import('@/views/sink/Index.vue'),
        //   meta: { src: require('@/assets/sink.jpg') },
        // },
        {
          path: 'privacypolicy',
          name: 'PrivacyPolicy',
          component: () => import('@/views/privacy-policy/Index.vue'),
        },
        {
          path: 'termsandconditions',
          name: 'TermsAndConditions',
          component: () => import('@/views/terms-and-conditions/Index.vue'),
        },
        {
          path: 'discover',
          name: 'Discover',
          component: () => import('@/views/discover/Index.vue'),
          meta: { src: require('@/assets/discover.jpg') },
        },
        {
          path: '*',
          name: 'FourOhFour',
          component: () => import('@/views/404/Index.vue'),
        },
      ],
    },
  ],
})

export default router
