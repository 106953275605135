<template>
  <div>
    <base-info-card
      :title="title"
      :subtitle="subtitle"
      space="4"
      color="primary"
    />
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <base-text-field
        v-model="publicMessage.SenderName"
        label="Name"
        required
      />

      <base-text-field
        v-model="publicMessage.SenderEmail"
        label="Email"
        required
      />

      <base-text-field
        v-model="publicMessage.Subject"
        label="Subject"
        required
      />

      <base-textarea
        v-model="publicMessage.Message"
        required
        class="mb-6"
        label="Your Need & Description"
      />
      <base-btn
        :color="!theme.isDark ? 'accent' : 'white'"
        outlined
        target="_blank"
        @click="submit"
      >
        Send message
      </base-btn>
    </v-form>
    <v-snackbar
      v-model="snackbar"
      :top="isTop"
      :timeout="timeout"
      :color="snackBarColor"
    >
      {{ snackBarMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
  export default {
    name: 'BaseContactForm',
    // Injected from the Vuetify Themeable mixin
    inject: ['theme'],
    props: {
      subtitle: String,
      title: {
        type: String,
        default: 'SEND US A MESSAGE',
      },
    },
    data: () => ({
      snackbar: false,
      snackBarMessage: 'dsadsad',
      isTop: true,
      snackBarColor: '',
      timeout: 5000,
      valid: false,
      publicMessage: {
        SenderName: '',
        SenderEmail: '',
        Subject: '',
        Message: '',
      },
    }),
    methods: {
      submit (e) {
        this.$refs.form.validate()
        const model = this
        if (this.$refs.form.validate(true)) {
          this.$recaptcha('submit').then((token) => {
            model.publicMessage.captchaToken = token
            this.axios.post('https://api.scrumteamondemand.com/api/publicmessage', this.publicMessage)
              .then(function (response) {
                model.publicMessage.SenderName = ''
                model.publicMessage.SenderEmail = ''
                model.publicMessage.Subject = ''
                model.publicMessage.Message = ''
                model.snackBarMessage = 'Your message has been submitted. We shall be in contact with you shortly.'
                model.snackbar = true
                model.snackBarColor = 'success'
              })
              .catch(function (error) {
                console.log(error)
                model.snackBarMessage = 'Error submitting your message. Please try again later.'
                model.snackBarColor = 'error'
                model.snackbar = true
              })
          })
        }
      },
    },
  }
</script>
