import NewsService from './news-service'
import CommunityService from './community-service'

const repositories = {
    news: NewsService,
    community: CommunityService,
}
export default {
    get: name => repositories[name],
}
