import service from '../../services/factory-service'
import Vue from 'vue'

const communityService = service.get('community')

const state = {
  community: {},
  tally: [],
}

const getters = {
  community: (communityState) => communityState.community,
  tally: (communityState) => communityState.tally,
}

const actions = {
  getTally: ({ commit }) => {
    commit('communityRequest')
    communityService.getTally()
      .then((result) => {
        commit('communityTally', result.data)
      },
        (errors) => {
          commit('communityError', errors)
        })
  },
}

const mutations = {
  communityRequest: (communityState) => {
    communityState.status = 'attempting request for community data'
  },
  communitySuccess: (communityState, communityResp) => {
    communityState.status = 'success'
    Vue.set(communityState, 'community', communityResp)
  },
  communityTally: (communityState, tally) => {
    communityState.status = 'success'
    Vue.set(communityState, 'tally', tally)
  },
  communityError: (communityState) => {
    communityState.status = 'error'
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
