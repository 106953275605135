import Client from './clients/axios-client'
const resource = '/api/news'

export default {
  getCategories () {
    return Client.get(`${resource}/categories`)
  },
  getTags () {
    return Client.get(`${resource}/tags`)
  },
  getNews () {
    return Client.get(`${resource}/five`)
  },
}
